<template>
  <div class="login">
    <div class="container login-wrapper">
      <div class="row align-items-center">
        <div class="col-md-5">
          <img src="../assets/login-img.svg" alt="" />
        </div>
        <div class="col-md-7">
          <h3>Login</h3>
          <form @submit.prevent="login" v-if="loginWithOTP == false">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-lab el">Email</label>
              <input
                type="text"
                class="form-control"
                id="email"
                v-model="userName"
                aria-describedby="emailHelp"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.userName.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-lab el"
                >Password</label
              >
              <input
                type="password"
                class="form-control"
                id="password"
                v-model="password"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.password.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="mb-3 action-div">
              <span v-if="this.loginType == 'Nest'"
                >login with
                <router-link
                  :to="{ name: 'otpEmailLogin', query: { name: 'Nest' } }"
                  >otp</router-link
                ></span
              >
              <span v-else
                >login with
                <router-link
                  :to="{ name: 'otpEmailLogin', query: { name: 'Pupa' } }"
                  >otp</router-link
                ></span
              >
              <span v-if="this.loginType == 'Nest'"
                >Don’t have an account
                <router-link :to="{ name: 'nestRegister' }"
                  >Register</router-link
                ></span
              >
              <span v-else
                >Don’t have an account
                <router-link :to="{ name: 'register' }"
                  >Register</router-link
                ></span
              >
            </div>
            <div class="mb-1 action-div float-right">
              <span v-if="this.loginType == 'Nest'"
                ><router-link
                  :to="{ name: 'forgotPassword', query: { name: 'Nest' } }"
                  >forgot-password?</router-link
                ></span
              >
              <span v-else
                ><router-link
                  :to="{ name: 'forgotPassword', query: { name: 'Pupa' } }"
                  >forgot-password?</router-link
                ></span
              >
            </div>
            <div class="text-right">
              <button
                type="submit"
                :disabled="isAuthenticating == true"
                class="btn cta-primary"
              >
                <span v-if="isAuthenticating == false"> Login </span>
                <span v-else>
                  <circular-loader></circular-loader>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import AuthService from "@/services/AuthService";
import CircularLoader from "@/components/loaders/CircularLoader";
import "bootstrap";
import { required, helpers } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  name: "Login",
  components: {
    CircularLoader
  },
  data() {
    return {
      userName: "",
      password: "",
      authStatus: "",
      token: "",
      user: "",
      loginType: this.$route.query.p,
      loginWithOTP: false,
      isAuthenticating: false,
      otp: "",
      showOtpField: false
    };
  },
  validations() {
    return {
      userName: {
        required: helpers.withMessage("Please enter user name", required)
      },
      password: {
        required: helpers.withMessage("Please enter password", required)
      },
      otp: {
        required: helpers.withMessage("Please enter otp", required)
      }
    };
  },
  methods: {
    login: function() {
      //alert(this.loginType);
      this.v$.userName.$touch();
      this.v$.password.$touch();
      if (this.v$.userName.$invalid || this.v$.password.$invalid) {
        return;
      }
      this.isAuthenticating = true;
      let user_name = this.userName;
      let password = this.password;
      AuthService.login({
        user_name,
        password
      })
        .then(result => {
          this.authStatus = result.data.status;
          if (this.authStatus == "error") {
            this.$toast.error(result.data.message);
            this.$store.dispatch("studentAuth/authError");
            this.isAuthenticating = false;
          } else {
            this.$toast.success(result.data.message);
            const userData = {
              token: result.data.token,
              user: result.data.user
            };
            this.$store.dispatch("studentAuth/authSuccess", userData);
            this.isAuthenticating = true;
            if (this.$store.state.studentAuth.nextUrl == "") {
              this.$router.push({
                name: "dashboardTab"
              });
            } else {
              this.$router.push(this.$store.state.studentAuth.nextUrl);
              this.$store.dispatch("studentAuth/updateNextUrl", "");
            }
          }
        })
        .catch(error => {
          this.error = error;
          this.$store.dispatch("studentAuth/authError");
          this.isAuthenticating = true;
        });
    },
    loginWithOtp() {
      const data = {
        user_name: this.userName,
        otp: this.otp
      };
      this.v$.userName.$touch();
      this.v$.otp.$touch();
      if (this.v$.userName.$invalid) {
        return;
      }
      if (this.v$.otp.$invalid && this.showOtpField == true) {
        return;
      }
      this.isAuthenticating = true;
      AuthService.getOTP(data)
        .then(result => {
          this.isAuthenticating = false;
          if (result.data.status == "otp_send") {
            this.showOtpField = true;
            this.$toast.success(result.data.message);
          } else if (result.data.status == "login_success") {
            this.$toast.success(result.data.message);
            const userData = {
              token: result.data.token,
              user: result.data.user
            };
            this.$store.dispatch("studentAuth/authSuccess", userData);
            this.isAuthenticating = false;
            if (this.$store.state.studentAuth.nextUrl == "") {
              this.$router.push({
                name: "profile"
              });
            } else {
              this.$router.push(this.$store.state.studentAuth.nextUrl);
              this.$store.dispatch("studentAuth/updateNextUrl", "");
            }
          } else {
            this.$toast.error(result.data.message);
          }
        })
        .catch(error => {
          this.error = error;
          this.isAuthenticating = false;
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/login.scss";
</style>
